import { ICommentInput } from "@interfaces/comment.interface";
import { HttpService } from "@services/base";

class ChannelService extends HttpService {
  private readonly prefix: string = "v2/channels";

  getChannels = (): Promise<any> => this.get(`${this.prefix}`);

  getCurrentChannel = (): Promise<any> => this.get(`${this.prefix}/current`);

  getSubscribers = (): Promise<any> => this.get(`${this.prefix}/subscribers`);

  getChannelUnsubscribe = (id: string): Promise<any> =>
    this.get(`${this.prefix}/${id}/unsubscribe`);

  getSubscriptions = (): Promise<any> =>
    this.get(`${this.prefix}/subscriptions`);

  getChannel = (id: string): Promise<any> => this.get(`${this.prefix}/${id}`);

  getChannelVideos = (id: string, page: number): Promise<any> =>
    this.get(`${this.prefix}/${id}/videos?page=${page}`);

  createChannel = (body: ICommentInput): Promise<any> =>
    this.post(`${this.prefix}`, body);

  updateChannel = (body: any): Promise<any> =>
    this.patch(`${this.prefix}/`, body);

  follow = (id: string): Promise<any> =>
    this.get(`${this.prefix}/${id}/follow`);

  unfollow = (id: string): Promise<any> =>
    this.get(`${this.prefix}/${id}/unfollow`);
}

export const channelService = new ChannelService();
