import React from "react";

export default function VideoListLoading() {
  return (
    <div className="at-videoslist">
      <div className="shimmering">
        <div className="photo shine"></div>
        <div className="shine title"></div>
        <div>
          <div className="lines shine"></div>
          <div className="lines shine"></div>
        </div>
      </div>

      <div className="shimmering">
        <div className="photo shine"></div>
        <div className="shine title"></div>
        <div>
          <div className="lines shine"></div>
          <div className="lines shine"></div>
        </div>
      </div>

      <div className="shimmering">
        <div className="photo shine"></div>
        <div className="shine title"></div>
        <div>
          <div className="lines shine"></div>
          <div className="lines shine"></div>
        </div>
      </div>

      <div className="shimmering">
        <div className="photo shine"></div>
        <div className="shine title"></div>
        <div>
          <div className="lines shine"></div>
          <div className="lines shine"></div>
        </div>
      </div>

      <div className="shimmering">
        <div className="photo shine"></div>
        <div className="shine title"></div>
        <div>
          <div className="lines shine"></div>
          <div className="lines shine"></div>
        </div>
      </div>
    </div>
  );
}
