import React, { useMemo, useState } from "react";
import VideoBoxComponent from "../video/video-box.component";
import "moment/locale/en-gb";
import "moment/locale/es";
import { ButtonGroup, ToggleButton } from 'react-bootstrap';
import useTranslation from "next-translate/useTranslation";
import InfiniteScroll from "react-infinite-scroller";
import Ads from "@components/ads/ads.component";
import { VideoCardComponent } from "@components/shared-components";
import { ModalProvider } from "@context/modal.context";
import { useAuth } from "@hooks/useAuth";
import { BtnComponent } from "@components/btn/btn.component";
import VideoListLoading from "@components/video-list/video-list-loading.component";
import _debounce from "lodash/debounce";
import globalStyles from '../styles/global.js';
import { CATEGORY_LIST } from "constants/category.list.contstants";

interface IVideoList {
  videos: any[];
  next: boolean;
  loading: boolean;
  nextPage: () => void;
  error: string;
  streamDuration?: object;
  streamViews?: object;
  channel?: boolean;
}

export default function VideoList({
  videos,
  next,
  loading,
  nextPage,
  error,
  streamDuration = {},
  streamViews = {},
  channel,
}: IVideoList) {
  const { user } = useAuth();
  const { t } = useTranslation("common");
  const [checked, setChecked] = useState(true);
  const [radioValue, setRadioValue] = useState('1');

  const sortedVideos = useMemo(() => {
    if (radioValue === '1') {
      return checked
        ? videos.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()) // Newest first
        : videos.sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()); // Oldest first
    } else {
      return videos; // Add other sorting criteria if needed
    }
  }, [videos, checked, radioValue]);

  return (
    <>
      <div className="at-container-pandding">
        {videos.length === 0 && loading && <VideoListLoading />}
        {videos.length === 0 && error && !loading && (
          <div className="at-videoslist">
            <h3>{error}</h3>
          </div>
        )}
        {channel == true ? (
          <div className="at-filters" style={{ marginLeft: "25px", display: "flex", alignItems: "center" }}>
            <span style={{ fontSize: "16px" }}>{t("channelPage.quickFilter")}:</span>&nbsp;&nbsp;&nbsp;
            <ButtonGroup className="at-channel-btngroup">
              <ToggleButton
                type="radio"
                name="radio"
                value="1"
                className={radioValue == "1" ? "at-channel-btn-active" : "at-channel-btn"}
                onChange={(e) => { setRadioValue(e.currentTarget.value); setChecked(!checked) }}
              >
                {radioValue == "1" && (checked ? (
                  <i>
                    <img src="/assets/images/arrow-down.svg" alt="down" width="20" height="20" />
                  </i>
                ) : (
                  <i>
                    <img src="/assets/images/arrow-up.svg" alt="up" width="20" height="20" />
                  </i>
                ))}&nbsp;{t("channelPage.filters.moreRecent")}
              </ToggleButton>
              <ToggleButton
                type="radio"
                name="radio"
                value="2"
                className={radioValue == "2" ? "at-channel-btn-active" : "at-channel-btn"}
                onChange={(e) => { setRadioValue(e.currentTarget.value); setChecked(!checked) }}
              >
                {radioValue == "2" && (checked ? (
                  <i>
                    <img src="/assets/images/arrow-down.svg" alt="down" width="20" height="20" />
                  </i>
                ) : (
                  <i>
                    <img src="/assets/images/arrow-up.svg" alt="up" width="20" height="20" />
                  </i>
                ))}&nbsp;{t("channelPage.filters.popular")}
              </ToggleButton>
            </ButtonGroup>
          </div>
        ) : (
          <div className="at-container-category-list">
            {CATEGORY_LIST.map((category: any) => (
              <BtnComponent key={category.value} url={category.url} name={category.name} active={true} />
            ))}
          </div>
        )}
        {sortedVideos.length > 0 && (
          <InfiniteScroll
            className="at-nfiniteloader at-videoloader"
            pageStart={1}
            hasMore={next}
            loadMore={_debounce(() => nextPage(), 100)}
            loader={
              <div className="spinner-border" role="status">
                <i className="fas fa-circle-notch fa-spin" />
              </div>
            }
          >
            <div className="at-videoslist" key={"video-list"}>
              {sortedVideos.map((video: any, index: number) =>
                video.type == "advert"
                  ? (
                    <div className="at-addthumbnail" key={`${video.id}-${index}`}>
                      <Ads type="block" />
                    </div>
                  )
                  : video && video.title && (
                    <div className="at-videobox" key={video.id}>
                      <ModalProvider channel={video?.channelId}>
                        <VideoCardComponent {...{ video, user, streamDuration }} />
                        <VideoBoxComponent {...{ video, user, streamViews }} />
                      </ModalProvider>
                    </div>
                  )
              )}
            </div>
          </InfiniteScroll>
        )}
        <style jsx>{`
          .at-container-pandding {
            background-color: #0E1121;
          }
          
          .at-addthumbnail {
            display: grid;
            justify-content: center;
          }

          .at-filter-button {
            color: white;
          }

          h4 {
            font-size: 13px;
            font-weight: 600;
            color: #2f3542;
            margin-bottom: 5px;
            font-family: var(--primary-font) !important;
            -webkit-font-smoothing: antialiased;
            line-height: 18px;
          }

          .at-container-category-list {
            padding-top: 20px;
            padding-bottom: 20px;
            padding-left: 25px;
            display: flex;
            flex-wrap: wrap;
            gap: 10px;
            overflow-x: auto;
          }

          .at-container-category-list::-webkit-scrollbar {
            height: 6px;
          }

          .at-container-category-list::-webkit-scrollbar-thumb {
            background: #888;
            border-radius: 10px;
          }

          .at-container-category-list::-webkit-scrollbar-thumb:hover {
            background: #555;
          }
        `}</style>
        <style jsx global>{globalStyles}</style>
      </div>
    </>
  );
}
