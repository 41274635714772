export const CATEGORY_LIST = [
    {
        value: "all",
        name: "All",
        url: "#",
    },
    {
        value: "science",
        name: "Science",
        url: "#",
    },
    {
        value: "game",
        name: "Games",
        url: "#",
    },
    {
        value: "physical",
        name: "Physical",
        url: "#",
    },
    {
        value: "policy",
        name: "Policy",
        url: "#",
    },
    {
        value: "programming",
        name: "Programming",
        url: "#",
    },
    {
        value: "design",
        name: "Design",
        url: "#",
    },
    {
        value: "creativity",
        name: "Creativity",
        url: "#",
    },
    {
        value: "cinema",
        name: "Cinema",
        url: "#",
    }
];
