import Link from "next/link";
import React, {useEffect, useMemo, useState} from "react";
import moment from "moment";
import "moment/locale/en-gb";
import "moment/locale/es";
import { numberWithSufix } from "@hooks/functions";
import useTranslation from "next-translate/useTranslation";
import {useSubscription} from "@hooks/useSubscription";
import { ProfilePictureComponent } from "@components/shared-components";

export default function VideoBoxComponent({ video, user, locale, streamViews = {} }: any) {
  const { title = "", creator = {}, views, createdAt } = video;
  const { t } = useTranslation("common");
  const [subscribed, {getChannelSubscription}] = useSubscription();
  const creatorUsername = creator.username;

  useEffect(() => {
    if (user && video.channelId?.id) {
      getChannelSubscription(video.channelId?.id, user._id);
    }
    // eslint-disable-next-line
  }, [user, video.channelId]);

  if (locale == "en") {
    moment.locale("en-gb");
  } else {
    moment.locale("es");
  }


  return useMemo(
    () => (
      <div className="video-box-component">
        <div>
          <ProfilePictureComponent 
            img={creator && creator.avatar}
            initials={creator && creator.username}
            size={40}
          />
        </div>
        <div style={{ width: "inherit"}}>
          <Link href={`/video/${video.id}`} replace={false}>
            <a>
              <h4>{title.length>35? title.slice(0,33)+"...":title}</h4>
            </a>
          </Link>
          <div className="at-videoinfobox streamer">
            <Link href={`/${creatorUsername}`}>
              <a>
                <span className="at-dalasreview streamer__name">
                  <i className="icon-shining-star-1"></i>
                  <span className="text">{creatorUsername}</span>
                </span>
              </a>
            </Link>
            <span className="streamer__details">
              <span className="at-views">
                <i className="fa fa-eye"></i>
                <span id={"views-" + video.id}>{video.id in streamViews ? numberWithSufix(streamViews[video.id]) : numberWithSufix(views)}</span> {t("videoCard.views")}{" "}
              </span>{" "}
              - {video.isLiveStream ? "En directo" : moment(createdAt).fromNow()}
            </span>
          </div>
        </div>
        <style jsx>{`
          .video-box-component {
            padding: 0px 5px;
            height: 40px;
            display: inline-flex;
            gap: 10px;
            width: inherit;

            h4 {
              font-size: 12px;
              font-weight: 450;
              color: #E7F3F3;
              // margin-bottom: 5px;
              font-family: var(--primary-font) !important;
              -webkit-font-smoothing: antialiased;
              // line-height: 15px;
              overflow:hidden;
            }
            
            .icon-shining-star-1 {
              display: inline-block;
              width: 12px;
              height: 12px;
              background: url('/assets/images/star.svg') no-repeat center center;
              background-size: contain;
            }

            .streamer {
              display: flex;
              justify-content: space-between;

              &__name {
                .text {
                  color: #3A4065;
                  font-weight: 450;
                  font-size: 10px;
                  font-family: var(--primary-font) !important;
                  -webkit-font-smoothing: antialiased;
                  line-height: normal;
                }

                i {
                  margin-right: 5px;
                }

                margin-right: 5px;
              }

              &__details {
                color: #3A4065;
                font-weight: 450;
                font-size: 10px;
                font-family: var(--primary-font) !important;
                -webkit-font-smoothing: antialiased;
                // margin: 0px;
                // display: inline !important;

                .at-views {
                  i {
                    margin-right: 5px;
                  }
                }
              }
            }
          }
        `}</style>
      </div>
    ),
    [user, video.subscribersOnly, video.id, subscribed?.subscribed, title, creatorUsername, views, t, createdAt]
  );
}
